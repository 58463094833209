<template>
  <div>
    <v-card flat>
      <v-card-title
        ><h3 class="grey--text">
          <v-icon class="mr-2" large>mdi-note-text-outline</v-icon>Invoice Card
        </h3>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-divider class="mb-3"></v-divider>
        <v-text-field
          label="Search"
          prepend-icon="mdi-magnify"
          v-model="search"
        ></v-text-field>

        <div v-if="ready">
          <v-data-table
            dense
            :search="search"
            :items="invoices"
            :headers="headers"
            :items-per-page="items_per_page"
          >
            <!-- PURPOSE -->
            <template v-slot:item.invoice_status="{ item }">
              <span
                v-if="item.invoice_status == 'Waiting for Payment'"
                class="red--text font-weight-black"
              >
                PENDING PAYMENT
              </span>
              <span
                v-if="item.invoice_status == 'Paid'"
                class="green--text font-weight-black"
              >
                PAID
              </span>
              <span v-else>
                <span
                  v-if="item.invoice_status == 'With Balance'"
                  class="warning--text font-weight-black"
                >
                  WITH BALANCE
                </span>
              </span>
            </template>

            <template v-slot:item.is_invoice="{ item }">
              <v-chip color="blue" class="white--text" v-if="item.is_invoice"
                >Invoice</v-chip
              >
              <v-chip color="red" class="white--text" v-else>Bill</v-chip>
            </template>
          </v-data-table>
        </div>
        <div v-else class="d-flex justify-center">
          <h2>Data Table is Loading..</h2>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["customer_id", "invoices"],
  data() {
    return {
      display_price_modal: false,
      loading: false,
      dialog: true,
      search: "",
      items_per_page: 5,
      ready: true,
      show_add_invoice: false,
      headers: [
        { text: "ID", value: "id", width: 30 },
        {
          text: "Invoice Code",
          value: "invoice_code",
          width: 100,
        },
        { text: "Balance", value: "balance" },
        { text: "Total Amount", value: "total_amount", width: 30 },
        { text: "Date", value: "invoice_date", width: 100 },
        { text: "Status", value: "invoice_status" },
        { text: "Purpose", value: "purpose" },
        { text: "Type", value: "is_invoice" },
      ],
    };
  },

  methods: {},
};
</script>

<style lang="scss" scoped></style>
